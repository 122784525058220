import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styled/globalStyles";
import { theme } from "./styled/theme";
import './styled/global.css'
import Routes from './routes';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingPage } from "./components";
import '@tomtom-international/web-sdk-maps/dist/maps.css'
const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <LoadingPage />
        <ToastContainer />
        <GlobalStyle />
        <Routes />
        <ReactQueryDevtools initialIsOpen={false} />
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App;