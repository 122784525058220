export interface IUser {
  cargo?: string
  celular?: string
  cpf_cnpj?: string
  created_at?: string
  eh_representante?: string
  email?: string
  empresa?: string
  endereco?: string
  id?: string
  aceito?:string,
  liberado?:string,
  ativo?:string,
  modalidade?: string
  nome?: string
  telefone?: string
  token?: string
  updated_at?: string
  locador?: any   // ILocador
  locatario?: ILocatario
  administrador?: any
  master_id?: string,
  tipo_usuario_descricao?: TipoUsuarioDescicaoEnum
  tipo_usuario?: number
}

export enum TipoUsuarioDescicaoEnum {
  DESLOGADO = 0,
  GESTOR = 2,
  ENGENHEIRO = 3
}

export interface IContext extends IUser {
  user: IUser;
  authenticate: (user: IUser) => void;
  logout: () => void;
}

export interface IAuthProvider {
  children: JSX.Element;
}

export interface ILocador {
  conta_corrente: string
  created_at: string
  id: number
  updated_at: string
  url_cartao_cnpj?: string
  url_contrato_social?: string
  url_cpf: "https://muvrental.com.br//storage/users/2/documents/locador/20220419005212_IOgFhS.pdf"
  url_rg?: string
  usuario_id: number
}

export interface ILocatario {
  created_at?: string
  id?: string
  paga_boleto?: string
  paga_cartao_credito?: string
  updated_at?: string
  url_cartao_cnpj?: string
  url_comprovante_endereco?: string
  url_contrato_social?: string
  url_cpf?: string
  url_faturamento?: string
  url_rg?: string
  usuario_id?: string
}