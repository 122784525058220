export const theme = {
  background: '#fff',
  light: {
    0: '#d2d3d5',
  },
  dark: {
    0: '#999797',
    1: '#595959',
    2: '#373435'
  },
  primary: {
    0: '#183996',
    1: '#0d1e4f',
  },
  secondary: {
    0: '#ff9a2e',
    1: '#c97a26'
  },
  green: {
    0: '#55BE6E',
    1: '#3b9450'
  },
  purple: {
    0: '#3e4095'
  },
  success: '#55BE6E',
  warning: '#FF8F39',
  danger: '#E8505B',
  fonts: {
    montserrat: `'Montserrat', sans-serif`,
    poppins: `'Poppins', sans-serif`
  },
  fontSize: {
    small: '10px',
    medium: '14px',
    large: '18px',
    ultra: '25px'
  },
  fontWeight: {
    small: '400',
    regular: '500',
    medium: '600',
    large: '700',
    extraBold: '800',
    black: '900'
  }
};