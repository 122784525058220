import {Fragment, useEffect} from 'react';
import { Header, HeaderCadastro, Footer, SidebarLocador, SidebarLocatario, SidebarAdministrador } from '../'
import { BodyRegister, ContainerFluid, Container, Row, Col } from '../../styled/globalStyles'
import styles from './Main.module.css'
import {useAuth} from "../../context/auth/useAuth";
import {me} from "../../services/userRequest";
import {useHistory} from "react-router";

interface IMain {
  children: React.ReactNode
}

const bodySize = document.body.scrollHeight

export const Main = ({ children }: IMain) => {
  // const screenSize = window.screen.height
  // const rootSize = document.getElementById('root').clientHeight

  return (
    <Fragment>
      <Header />
      {children}
      <Footer marginTop={bodySize >= 700 ? 0 : 250} />
    </Fragment>
  )
}

export const MainAdministrador = ({ children }: IMain) => {
  return (
    <Main>
      <ContainerFluid className="container-fluid mt-4 mb-4">
        <Container className="container">
          <Row className="row">
            <Col className="col-12 col-sm-12 col-md-12 col-lg-3 col-lx-3 col-xxl-3 d-xxl-block d-lx-block d-lg-block d-none">
              <SidebarAdministrador />
            </Col>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-9 col-lx-9 col-xxl-9">
              {children}
            </Col>
          </Row>
        </Container>
        <div style={{ height: '250px' }}></div>
      </ContainerFluid>
    </Main>
  )
}


export const MainLocador = ({ children }: IMain) => {

  const { user,authenticate } = useAuth();
  const history = useHistory();

  useEffect(() => {

    if(!user.liberado) {
      if(user.locador) {
        history.push('/locador/criar-conta/homologacao');
        return;
      }

      if(user.locatario) {
        history.push('/locatario/criar-conta/homologacao');
        return;
      }
    }

    if(!user.aceito) {
      if(user.locador) {
        history.push('/locador/criar-conta/termos-de-uso');
        return;
      }

      if(user.locatario) {
        history.push('/locatario/criar-conta/termos-de-uso');
        return;
      }
    }
  }, [])


  return (
    <Main >
      <ContainerFluid className="container-fluid mt-4 mb-4" >
        <Container className="container">
          <Row className="row">
            <Col className="col-12 col-sm-12 col-md-12 col-lg-3 col-lx-3 col-xxl-3 d-xxl-block d-lx-block d-lg-block d-none">
              <SidebarLocador />
            </Col>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-9 col-lx-9 col-xxl-9">
              {children}
            </Col>
          </Row>
        </Container>
        <div style={{ height: '250px' }}></div>
      </ContainerFluid>
    </Main>
  )
}

export const MainLocatario = ({ children }: IMain) => {

  const { user,authenticate } = useAuth();
  const history = useHistory();



  useEffect(() => {
    if(!user.liberado) {
      if(user.locador) {
        history.push('/locador/criar-conta/homologacao');
        return;
      }

      if(user.locatario) {
        history.push('/locatario/criar-conta/homologacao');
        return;
      }
    }

    if(!user.aceito) {
      if(user.locador) {
        history.push('/locador/criar-conta/termos-de-uso');
        return;
      }

      if(user.locatario) {
        history.push('/locatario/criar-conta/termos-de-uso');
        return;
      }
    }
  }, [])


  return (
    <Main>
      <ContainerFluid className="container-fluid mt-4 mb-4">
        <Container className="container">
          <Row className="row">
            <Col className="col-12 col-sm-8 col-md-8 col-lg-9 col-lx-9 col-xxl-9">
              {children}
            </Col>
            <Col className="col-12 col-sm-4 col-md-4 col-lg-3 col-lx-3 col-xxl-3 d-xxl-block d-lx-block d-lg-block d-none">
              <SidebarLocatario />
            </Col>
          </Row>
          <div style={{ height: '250px' }}></div>
        </Container>
      </ContainerFluid>
    </Main>
  )
}

export const MainCadastro = ({ children }: IMain) => {
  return (
    <BodyRegister className={`${styles.full_blue}`}>
      <HeaderCadastro />
      <ContainerFluid className="container-fluid mt-4">
        <Container className="container">
          <Row className="row d-flex justify-content-center">
            {children}
          </Row>
        </Container>
        <div style={{ height: '250px' }}></div>
      </ContainerFluid>
      <Footer reference="register" marginTop={bodySize >= 700 ? 0 : 250} />
    </BodyRegister>
  )
}

export const MainLanding = ({children}) => {
  return (
      <>
        {children}
      </>
  );
}